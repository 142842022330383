// import ticket from "../components/img/ticket.jpg";
import greenShirt from "../components/img/green-shirt.jpg";
import whiteShirt from "../components/img/white-shirt.jpg";
import greenVShirt from "../components/img/green-shirt-v.jpg";
import whiteVShirt from "../components/img/white-shirt-v.jpg";
import greenHoodie from "../components/img/green-hoodie.jpg";
import whiteHoodie from "../components/img/white-hoodie.jpg";

// Conference Hoodie (White) Key: price_1OS3M5BvXyDWVtmIhdSMDPOO
// Conference Hoodie (Green) Key: price_1OS3LmBvXyDWVtmIrhn21QO0

// Conference V-Neck Shirt (White) Key: price_1OS3LKBvXyDWVtmIUvplYWK9
// Conference V-Neck Shirt (Green) Key: price_1OS3KvBvXyDWVtmIGe6dU8Bm

// Conference Shirt (White) Key: price_1OS3KfBvXyDWVtmI1TyKMtSC
// Conference Shirt (Green) Key: price_1OS3KOBvXyDWVtmIY5xHf4xZ

// Ticket Key: price_1OSpe8BvXyDWVtmI6n85uySt

const productsArray = [
     // {
     //      id: "price_1OTou8BvXyDWVtmIte98LsyT",
     //      title: "Ticket",
     //      image: ticket,
     //      price: 50.00,
     //      size: ["small", "medium", "large", "xl", "2xl", "3xl"]
     // },
     // {
     //      id: "price_1OTou0BvXyDWVtmIFONuJelm",
     //      title: "Small Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "small"
     // },
     // {
     //      id: "price_1OTotsBvXyDWVtmIPBeNAHHc",
     //      title: "Small Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "small"
     // },
     // {
     //      id: "price_1OTothBvXyDWVtmICYDrAfOx",
     //      title: "Small V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "small"
     // },
     // {
     //      id: "price_1OTotZBvXyDWVtmIXw1s6i4z",
     //      title: "Small V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "small"
     // },
     // {
     //      id: "price_1OTotLBvXyDWVtmIWrobXoUG",
     //      title: "Small Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "small"
     // },
     // {
     //      id: "price_1OTotCBvXyDWVtmID6MCSY1O",
     //      title: "Small Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "small"
     // },
     // //  MEDIUM
     // {
     //      id: "price_1OToqMBvXyDWVtmIrhrrlPvs",
     //      title: "Medium Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "medium"
     // },
     // {
     //      id: "price_1OToqWBvXyDWVtmIDCQkvaJl",
     //      title: "Medium Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "medium"
     // },
     // {
     //      id: "price_1OToqgBvXyDWVtmIPJ4d6Q6G",
     //      title: "Medium V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "medium"
     // },
     // {
     //      id: "price_1OToqtBvXyDWVtmIj8za514c",
     //      title: "Medium V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "medium"
     // },
     // {
     //      id: "price_1OTorsBvXyDWVtmIyzhNVWAc",
     //      title: "Medium Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "medium"
     // },
     // {
     //      id: "price_1OTorHBvXyDWVtmIlyQy6NLY",
     //      title: "Medium Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "medium"
     // },
     // // LARGE
     // {
     //      id: "price_1OTopeBvXyDWVtmIaGMtosOL",
     //      title: "Large Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "large"
     // },
     // {
     //      id: "price_1OTomnBvXyDWVtmIOTE9nm01",
     //      title: "Large Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "large"
     // },
     // {
     //      id: "price_1OTomvBvXyDWVtmI5scBLUbB",
     //      title: "Large V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "large"
     // },
     // {
     //      id: "price_1OTon6BvXyDWVtmIwYy4Ra1o",
     //      title: "Large V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "large"
     // },
     // {
     //      id: "price_1OTonEBvXyDWVtmIOppJZ0bI",
     //      title: "Large Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "large"
     // },
     // {
     //      id: "price_1OTonMBvXyDWVtmILlaNWoCe",
     //      title: "Large Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "large"
     // },
     // // XL
     // {
     //      id: "price_1OTokeBvXyDWVtmIE3pO1I4f",
     //      title: "XL Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "xl"
     // },
     // {
     //      id: "price_1OTokmBvXyDWVtmINataXnJD",
     //      title: "XL Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "xl"
     // },
     // {
     //      id: "price_1OToksBvXyDWVtmInEAyNmgQ",
     //      title: "XL V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "xl"
     // },
     // {
     //      id: "price_1OTokzBvXyDWVtmItuvi6Sti",
     //      title: "XL V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "xl"
     // },
     // {
     //      id: "price_1OTol5BvXyDWVtmIEMz1w7K0",
     //      title: "XL Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "xl"
     // },
     // {
     //      id: "price_1OTolBBvXyDWVtmIizd29Wqe",
     //      title: "XL Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "xl"
     // },
     // // 2XL
     // {
     //      id: "price_1OToi1BvXyDWVtmIia4cT025",
     //      title: "2XL Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "2xl"
     // },
     // {
     //      id: "price_1OToi9BvXyDWVtmIAjYKYTU7",
     //      title: "2XL Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "2xl"
     // },
     // {
     //      id: "price_1OToiFBvXyDWVtmIAd0BMw6u",
     //      title: "2XL V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "2xl"
     // },
     // {
     //      id: "price_1OToiNBvXyDWVtmIZ0pTZfKo",
     //      title: "2XL V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "2xl"
     // },
     // {
     //      id: "price_1OToiUBvXyDWVtmIwQIql8gZ",
     //      title: "2XL Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "2xl"
     // },
     // {
     //      id: "price_1OToiaBvXyDWVtmIngfbdl5v",
     //      title: "2XL Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "2xl"
     // },
     // // 3XL
     // {
     //      id: "price_1OTof1BvXyDWVtmIclzikj0J",
     //      title: "3XL Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "3xl"
     // },
     // {
     //      id: "price_1OToflBvXyDWVtmIDhKzpJIp",
     //      title: "3XL Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "3xl"
     // },
     // {
     //      id: "price_1OToftBvXyDWVtmIWAbHk5vJ",
     //      title: "3XL V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "3xl"
     // },
     // {
     //      id: "price_1OTofyBvXyDWVtmImOjsPcfi",
     //      title: "3XL V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "3xl"
     // },
     // {
     //      id: "price_1OTog2BvXyDWVtmIOFmbFmPV",
     //      title: "3XL Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "3xl"
     // },
     // {
     //      id: "price_1OTogTBvXyDWVtmInrgjjKep",
     //      title: "3XL Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "3xl"
     // },
     // // 4XL
     // {
     //      id: "price_1OUFA5BvXyDWVtmIOgykLIMG",
     //      title: "4XL Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "4xl"
     // },
     // {
     //      id: "price_1OUFAhBvXyDWVtmI1nJs6qbT",
     //      title: "4XL Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "4xl"
     // },
     // {
     //      id: "price_1OUFBSBvXyDWVtmIIqqi4PZB",
     //      title: "4XL V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "4xl"
     // },
     // {
     //      id: "price_1OUFC6BvXyDWVtmIEVmJYRmk",
     //      title: "4XL V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "4xl"
     // },
     // {
     //      id: "price_1OUFCrBvXyDWVtmIwNRuXXnm",
     //      title: "4XL Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "4xl"
     // },
     // {
     //      id: "price_1OUFDGBvXyDWVtmIQD5JEuR6",
     //      title: "4XL Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "4xl"
     // },
     // // 5XL
     // {
     //      id: "price_1OUFEgBvXyDWVtmIWzwzweKC",
     //      title: "5XL Shirt (Green)",
     //      image: greenShirt,
     //      price: 30.00,
     //      size: "5xl"
     // },
     // {
     //      id: "price_1OUFF6BvXyDWVtmIVSC774gP",
     //      title: "5XL Shirt (Tan)",
     //      image: whiteShirt,
     //      price: 30.00,
     //      size: "5xl"
     // },
     // {
     //      id: "price_1OUFFZBvXyDWVtmIp3CPPfzu",
     //      title: "5XL V-Neck Shirt (Green)",
     //      image: greenVShirt,
     //      price: 30.00,
     //      size: "5xl"
     // },
     // {
     //      id: "price_1OUFFxBvXyDWVtmIWHJM6Q6i",
     //      title: "5XL V-Neck Shirt (Tan)",
     //      image: whiteVShirt,
     //      price: 30.00,
     //      size: "5xl"
     // },
     // {
     //      id: "price_1OUFHUBvXyDWVtmInzAcqRJ3",
     //      title: "5XL Hoodie (Green)",
     //      image: greenHoodie,
     //      price: 45.00,
     //      size: "5xl"
     // },
     // {
     //      id: "price_1OUFI4BvXyDWVtmIfxRTnaPK",
     //      title: "5XL Hoodie (Tan)",
     //      image: whiteHoodie,
     //      price: 45.00,
     //      size: "5xl"
     // },
]

function getProductData(id) {
     let productData = productsArray.find(product => product.id === id)

     if (productData === undefined) {
          console.log("Product data does not exist for ID: " + id);
          return undefined;
     }

     return productData;
}

export { productsArray, getProductData };